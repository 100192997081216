import styled from 'styled-components';
import Theme, { dim } from '../styles/theme';

const StyledAlert = styled.div`
  position: fixed;
  bottom: ${dim._28px};
  ${props =>
    !props.isMobile
      ? `
          left: 50%;
          transform: translateX(-50%);
        `
      : `
          width: calc(100% - ${dim._30px});
          left: ${dim._scale(15)};
        `};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;

  &.topAlert {
    bottom: auto;
    top: ${dim._28px};
  }

  .cookieErrorView {
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 40px;
    flex-direction: column;
  }

  .alertContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props =>
      !props.isMobile
        ? `${dim._12px} ${dim._16px}`
        : `${dim._8px} ${dim._12px};`};
    ${props => (!props.isMobile ? 'max-width: 70rem;' : '')};
    color: ${Theme.color.base_100};
    background-color: ${Theme.color.base_10};
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.25);
    border-radius: 2px;
  }

  .alertIcon {
    height: ${props => (!props.isMobile ? dim._20px : dim._16px)};
    width: ${props => (!props.isMobile ? dim._20px : dim._16px)};
  }

  .rotate {
    animation: rotation 2s infinite linear;
  }

  .alertText {
    display: flex;
    align-items: center;
    margin: 0px ${dim._8px};
    ${props => (props.isMobile ? `font-size: ${dim._12px} !important;` : '')};
    white-space: nowrap;
  }

  .alertBtn {
    margin-left: ${dim._16px};
    color: ${Theme.color.yellow_50};
    cursor: pointer;
    white-space: nowrap;
  }

  .alertSubSection {
    display: flex;
    > .alertText {
      white-space: nowrap;
      font-size: ${dim._14px};
      font-weight: normal;
    }
    > button {
      width: fit-content;
      padding: ${dim._6px} ${dim._10px};
      margin-left: ${dim._10px};
      color: ${Theme.color.tomato};
      background-color: ${Theme.color.base_100};
      .buttonImage {
        height: ${dim._12px};
        width: ${dim._12px};
      }
    }
  }

  .alertSubSectionLeftPadded {
    padding-left: ${dim._30px};
  }

  .alertBtnImage,
  .closeIcon {
    cursor: pointer;
    margin-left: ${dim._10px};
    width: ${dim._20px};
    height: ${dim._20px};
  }
`;

export default StyledAlert;
