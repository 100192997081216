import React, { useEffect, useState } from "react";
import StyledAlert from "./Alert.styled";
import Image from "./Image";
import alertIcon from "../assets/alert.svg";
import closeIcon from "../assets/close-icon-white.svg";

/**
 * Alert component to render error/success messages
 * @param {String} type (either success or error)
 * @param {String} text (message to show)
 * @param {String} showAlert (to show or hide alert)
 */

function Alert(props) {
  const [alertAction, setAlertAction] = useState();
  const [show, setShow] = useState();
  const [type, setType] = useState();
  const [text, setText] = useState();

  useEffect(() => {
    const alert = props.alert;
    setType(alert.type);
    setText(alert.text);
    setShow(alert.showAlert);
    setAlertAction(alert.alertAction);
  }, [props.alert]);

  useEffect(() => {
    let timer = null;
    if (show && type !== "error_non_suspendable")
      timer = setTimeout(
        () => {
          setShow(false);
        },
        // eslint-disable-next-line no-nested-ternary
        type === "success"
          ? alertAction && alertAction.actionType != null
            ? 12000
            : 3000
          : 5000
      );
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [show, type, alertAction]);

  const onClose = () => setShow(false);

  const _handleAlertAction = () => {
    if (alertAction && alertAction.actionType && props.onActionPerformed)
      props.onActionPerformed(alertAction);
    setShow(false);
  };

  return show ? (
    <StyledAlert className="alertWrapper no-user-select">
      <div className="alertContainer">
        <Image
          hidden={type === "success"}
          className="alertIcon"
          src={alertIcon}
          alt="alert icon"
        />
        <div className="alertText body16">
          {text ||
            (navigator.onLine
              ? "An error has occurred. Please try again after some time."
              : "Please check your network connection and try again.")}
        </div>
        {type === "success" && alertAction && alertAction.actionType ? ( // TODO: Need to check this later
          <div className="alertBtn button14" onClick={_handleAlertAction}>
            {alertAction && alertAction.actionText}
          </div>
        ) : (
          ""
        )}
        <img
          className="closeIcon"
          src={closeIcon}
          alt="close icon"
          onClick={onClose}
        />
      </div>
    </StyledAlert>
  ) : null;
}

export default React.memo(Alert);
