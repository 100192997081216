import { createGlobalStyle } from 'styled-components';

export const minSupportedWidth = 1024;
export const maxSupportedWidth = 1366;
export const minSupportedWidthMobile = 320;
export const maxSupportedWidthMobile = 360;
export const maxSupportedWidthTablet = 768;
let innerWidth = {};

if (typeof window !== 'undefined') {
  innerWidth = window.innerWidth;
}
if (innerWidth <= maxSupportedWidthTablet) {
  innerWidth = maxSupportedWidthMobile;
} else if (innerWidth < minSupportedWidth) {
  innerWidth = minSupportedWidth;
} else if (innerWidth > maxSupportedWidth) {
  innerWidth = maxSupportedWidth;
}
export const scaleFactor =
  innerWidth <= maxSupportedWidthTablet ? innerWidth / 360 : innerWidth / 1366;

/* stylelint-disable property-no-vendor-prefix  */
/* stylelint-disable value-no-vendor-prefix  */
const GlobalStyle = createGlobalStyle`
  body, html {
    font-family: 'IBM Plex Sans', sans-serif;
    background-color: ${props => props.theme.color.base_90};
    color: ${props => props.theme.color.base_20};
    font-size: ${props => props.theme.dim._14px};
    margin: 0;
    height: 100%;
    scrollbar-width: none !important;
  }

  textarea {
   font-family: inherit;
   font-size: inherit;
  }

  input {
   font-family: inherit;
   font-size: inherit;
  }

  button {
   font-family: inherit;
   font-size: inherit;
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
  }

  img {
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
  }

  a {
    text-decoration: none;
    :active, :visited {
      color: ${props => props.color || props.theme.color.blue_50};
    }
  }

  * {
    outline: none;
  }

  #root {
    height: 100%;
    min-width: ${minSupportedWidth}px;
    @media (max-width: 768px) {
      min-width: ${minSupportedWidthMobile}px;
    }
    justify-content: center;
    position: relative;
  }

  .ssoLogin {
    font-size: 18px;
  }

  .loaderContainer {
    background: ${props => props.theme.color.transparent};
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  .loaderWrapper {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
  }

  .loader {
    color: ${props => props.theme.color.tomato};
    font-size: ${props => props.theme.dim._8px};
    width: 1em;
    height: 1em;
    border-radius: 50%;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;

  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }

  .icon {
    height: ${props => props.theme.dim._24px};
    width: ${props => props.theme.dim._24px};
  }

  .global-dropDown-container {
    position: relative;
  }

  .showDropDownUp {
    right: 0;
    bottom: ${props => props.theme.dim._32px};
  }

  .showDropDownNormal {
    right: 0;
  }

  /* font --------------- */
  .title56  {
    font-size: ${props => props.theme.dim._56px};
    font-weight: normal;
    line-height: 1.14;
    letter-spacing: -${props => props.theme.dim._scale(1.12)};
    font-family: inherit;
  }
  
  .header40 {
    font-size: ${props => props.theme.dim._40px};
    font-weight: normal;
    line-height: 1.2;
    letter-spacing: normal;
    font-family: inherit;
  }

  .header36{
    font-size: 36px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
  }

  .header32 {
    font-size: ${props => props.theme.dim._32px};
    font-weight: normal;
    line-height: 1.25;
    letter-spacing: normal;
    font-family: inherit;
  }

  .header24 {
    font-size: ${props => props.theme.dim._24px};
    font-weight: normal;
    line-height: 1.17;
    letter-spacing: normal;
    font-family: inherit;
  }

  .header12 {
  font-size: ${props => props.theme.dim._12px};
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: ${props => props.theme.dim._scale(0.24)};
  font-family: inherit;
}
  .subHeader20 {
  font-size: ${props => props.theme.dim._20px};
  font-weight: 600;
  line-height: 1.6;
  letter-spacing: normal;
  font-family: inherit;
}
  .header20 {
  font-size: ${props => props.theme.dim._20px};
  font-weight: 600;
  line-height: 1.2;
  letter-spacing: normal;
  font-family: inherit;
}
  .header16 {
  font-size: ${props => props.theme.dim._16px};
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: normal;
  font-family: inherit;
}
  .body16 {
  font-size: ${props => props.theme.dim._16px};
  font-weight: normal;
  line-height: 1.5;
  letter-spacing: normal;
  font-family: inherit;
}
  .button14 {
  font-size: ${props => props.theme.dim._14px};
  font-weight: 600;
  line-height: 1.14;
  letter-spacing: ${props => props.theme.dim._scale(0.28)};
  font-family: inherit;
}
  .tag14 {
  font-size: ${props => props.theme.dim._14px};
  font-weight: normal;
  line-height: 1.43;
  letter-spacing: normal;
  font-family: inherit;
}
.text14 {
  font-size: ${props => props.theme.dim._14px};
  font-weight: 500;
  line-height: 1.14;
  letter-spacing: ${props => props.theme.dim._scale(0.28)};
  font-family: inherit;
}
  .note14 {
  font-size: ${props => props.theme.dim._14px};
  font-weight: 600;
  line-height: 1.33;
  letter-spacing: ${props => props.theme.dim._scale(0.24)};
  font-family: inherit;
}

.text12 {
  font-size:  ${props => props.theme.dim._12px};
  font-weight: 500;
  line-height: 1.33;
  letter-spacing: ${props => props.theme.dim._scale(0.24)};
  font-family: inherit;
}

.textNormal12 {
  font-size:  ${props => props.theme.dim._12px};
  font-weight: normal;
  line-height: 1.67;
  letter-spacing: normal;
  font-family: inherit;
}

.timeStamp12 {
  font-size: ${props => props.theme.dim._12px};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
}

.textBold12 {
  font-size: ${props => props.theme.dim._12px};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: ${props => props.theme.dim._scale(0.24)};
  font-family: inherit;
}

.textItalic12 {
  font-size: ${props => props.theme.dim._12px};
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.label10 {
  font-size: ${props => props.theme.dim._10px};
  font-weight: 500;
  line-height: 12px;
  letter-spacing: ${props => props.theme.dim._scale(0.2)};
  font-family: inherit;
}

  .no-user-select{
    user-select: none; /* supported by Chrome and Opera */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none;
  }

.baseStyles__BaseModalBackground-sTbLb {
  height: 100% !important;
  width: 100% !important;
  @media (max-width: 768px) {
    height:-webkit-fill-available !important;
  }
}



  .__react_component_tooltip.type-dark.place-bottom:after,
  .__react_component_tooltip.type-dark.place-bottom:before {
    content: none;
  }

  input[type=number]::-webkit-inner-spin-button, /* remove up down button from input number */
  input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.showDarkBg {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 4;
  background-color: rgba(0,0,0,0);
  align-items: center;
  justify-content: center;
}



.react-tooltip-lite {
  background: #333;
  color: white;
  padding: ${props => props.theme.dim._4px} ${props =>
  props.theme.dim._10px} !important;
  border-radius: 2px;
  font-size: ${props => props.theme.dim._14px};
  width: auto !important;
}

.react-tooltip-lite-arrow {
  border-color: #333;
}

.tooltipContent {
  .react-tooltip-lite {
    width: ${props => props.theme.dim._scale(180)} !important;
  }
}

.ReactModalPortal {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  background-color: rgba(0,0,0,0.5);
  align-items: center;
  justify-content: center;
}

.ReactModal__Overlay {
  display: flex;
  justify-content: center;
}
.productLogo {
  width: ${props => props.theme.dim._scale(180)};
  margin-top: ${props => props.theme.dim._40px};
}
.productLogoPlaceholder {
  width: ${props => props.theme.dim._scale(180)};
  margin-top: ${props => props.theme.dim._40px};
  height: ${props => props.theme.dim._scale(45)};
}
.shimmer {
  background: #f6f7f8;
  background-image: linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%);
  background-repeat: no-repeat;
  background-size: 800px 104px; 
  display: inline-block;
  position: relative; 
  animation: placeholderShimmer 1s linear 0s infinite direction forwards;
}
@keyframes placeholderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0; 
  }
}
`;

/* stylelint-enable property-no-vendor-prefix  */
/* stylelint-enable value-no-vendor-prefix  */
export default GlobalStyle;
