import {
  signUp,
  signIn,
  confirmSignUp,
  signOut,
  resetPassword,
  confirmResetPassword,
  resendSignUpCode,
  signInWithRedirect,
  fetchAuthSession,
  autoSignIn,
} from "aws-amplify/auth";

import { Amplify } from "aws-amplify";
import { CookieStorage } from "aws-amplify/utils";
import { cognitoUserPoolsTokenProvider } from "aws-amplify/auth/cognito";

export const initiateAmplifyAuth = () => {
  Amplify.configure({
    Auth: {
      Cognito: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID,
        // REQUIRED - Amazon Cognito Region
        region: process.env.NEXT_PUBLIC_REGION,
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID,
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_WEB_CLIENT_ID,
        loginWith: {
          oauth: {
            domain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
            scopes: ["email", "openid"],
            redirectSignIn: [
              `${process.env.NEXT_PUBLIC_LOGIN_URL}/oauthcallback`,
            ],
            redirectSignOut: [`${process.env.NEXT_PUBLIC_LOGIN_URL}`],
            responseType: "code",
          },
        },
      },
    },
    Storage: {
      AWSS3: {
        bucket: process.env.NEXT_PUBLIC_S3_BUCKET,
        region: process.env.NEXT_PUBLIC_REGION,
      },
    },
  });
  cognitoUserPoolsTokenProvider.setKeyValueStorage(
    new CookieStorage({
      domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
    })
  );
};

const auth = {
  signUp: (name, email, password, sign_up_source = "email") => {
    if (name == null) return Promise.reject(new Error("Invalid name"));
    name = name.replace(/[^a-zA-Z ]/g, " ").trim();
    return signUp({
      username: email,
      password,
      options: {
        userAttributes: {
          email,
          name,
          "custom:sign_up_source": sign_up_source,
        },
        autoSignIn: true,
      },
    });
  },
  fetchAuthSession: () => fetchAuthSession(),
  confirmSignUp: (
    username,
    password,
    verificationCode,
    isAutoSignIn = true
  ) => {
    const confirmSignUpPromise = confirmSignUp({
      username,
      confirmationCode: verificationCode,
      options: {
        forceAliasCreation: true,
      },
    });
    if (isAutoSignIn) {
      return confirmSignUpPromise.then(() => autoSignIn());
    }
    return confirmSignUpPromise;
  },
  userWithEmailExist: (email) => {
    const code = "000000";
    return confirmSignUp({
      username: email,
      confirmationCode: code,
      options: {
        forceAliasCreation: false,
      },
    })
      .then(() => true)
      .catch((err) => {
        switch (err.name) {
          case "UserNotFoundException":
          case "LimitExceededException":
            return false;
          default:
            return true;
        }
      });
  },
  forgotPassword: (email) => resetPassword({ username: email.toLowerCase() }),
  forgotPasswordSubmit: (username, code, newPassword) =>
    confirmResetPassword({ username, newPassword, confirmationCode: code })
      .then(() => signOut({ global: true }))
      .then(
        () =>
          /* user's access token is getting revoked immediately post forget password success */
          new Promise((resolve) => {
            setTimeout(resolve, 500);
          })
      )
      .then(() => signIn({ username, password: newPassword })),
  signOut: () => signOut().catch(() => null),
  resendSignUpCode: (email) =>
    resendSignUpCode({ username: email.toLowerCase() }),
  federatedSignIn: (provider) =>
    signInWithRedirect({ provider: { custom: provider } }),
  signIn: (username, password) => signIn({ username, password }),
  autoSignIn: () => autoSignIn(),
};

export default auth;
