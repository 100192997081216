export const ErrorMsg = {
  INVALID_EMAIL: "Invalid email!",
  LOWERCASE_PASSWORD:
    "The password must contain at least 1 lowercase alphabetical character",
  UPPERCASE_PASSWORD:
    "The password must contain at least 1 uppercase alphabetical character",
  NUMERIC_PASSWORD: "The password must contain at least 1 numeric character",
  SPECIAL_CHARACTER: "The password must contain at least one special character",
  WRONG_EMAIL: "Your email might be wrong",
  PASSWORD_LENGTH_ERROR: "Password should be at least 6 characters long",
  EMPTY_NAME: "Name cannot be empty",
  EMPTY_VERIFICATION_CODE: "Verification Code cannot be empty",
  EMPTY_EMAIL: "Enter valid email",
  UNSAVED_CHANGES: "You have unsaved changes.",
};

export const PasswordLength = "6";

export const PeopleCuesSupportEmail = process.env.NEXT_PUBLIC_APP_SUPPORT_EMAIL;
