import React, { useRef, useState, useEffect } from "react";
import ReactGA from "react-ga4";
import * as Sentry from "@sentry/nextjs";
import { ThemeProvider } from "styled-components";
import { useRouter } from "next/router";
import { Hub } from "aws-amplify/utils";
import getSignedInWith, {
  isSignedInWithCognito,
} from "@xto10x/oauth2/lib/getSignedInWith";
import setCredentials from "@xto10x/oauth2/lib/setCredentials";
import getCredentials from "@xto10x/oauth2/lib/getCredentials";
import clearCredentials from "@xto10x/oauth2/lib/clearCredentials";
import jwtUtils from "@xto10x/oauth2/lib/jwtUtils";
import theme from "../styles/theme";
import GlobalStyles from "../styles/globalStyle";
import useWindowSize from "../styles/useWindowSize";
import { setScaleFactor } from "../styles/size";
import Alert from "../components/Alert";
import { register as registerSentry } from "../sentry.init";
import {
  ErrorMessageMap,
  appRedirectUrl,
  orgConfigApiEndpoint,
  appName,
  userInfoApiEndpoint,
} from "../utils/constants";
import analytics from "../utils/analytics";
import {
  getOrgDetailsFromSubDomain,
  isCognitoEnabled,
  hasInstanceAccess,
  checkAuthErrorAndDoOperation,
} from "../utils/utils";
import auth, { initiateAmplifyAuth } from "../utils/auth";
import { signIn } from "aws-amplify/auth";
import { PeopleCuesSupportEmail } from "../constants";

export const metadata = {
  icons: {
    icon: "/favicon.ico",
  },
};

const App = function App({ Component, pageProps }) {
  const router = useRouter();

  registerSentry();

  const { redirect_url, stopAutoLogin } = router.query;

  if (router.route === "/oauthcallback") {
    initiateAmplifyAuth();
  }

  const redirectUrl = useRef(appRedirectUrl);
  const [isEmailVerificationRequired, setIsEmailVerificationRequired] =
    useState(false);
  const [orgConfig, setOrgConfig] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [alertProps, setAlertProps] = useState({});
  const [email, setEmail] = useState(router.query?.email || "");
  const [isSignInProgress, setIsSignInProgress] = useState(false);
  const [showSSOLogin, setShowSSOLogin] = useState(false);

  const emailRef = useRef(null);
  const microsoftAuthEnabled = true;
  const isInitialized = true;

  useEffect(() => {
    analytics.initAnalytics();
  }, []);

  const authRedirectUrls = ["/oauthcallback", "/authcallback"];

  useEffect(() => {
    if (!authRedirectUrls.includes(router.route))
      localStorage.removeItem("redirect_url");
    const cachedRedirectUrl = localStorage.getItem("redirect_url");
    if (!authRedirectUrls.includes(router.route) && redirect_url) {
      const updatedRedirectUrl = redirect_url;
      localStorage.setItem("redirect_url", updatedRedirectUrl);
      redirectUrl.current = updatedRedirectUrl;
    } else if (cachedRedirectUrl) {
      redirectUrl.current = cachedRedirectUrl;
      router.replace({
        pathname: window.location.pathname,
        query: {
          ...router.query,
          redirect_url: cachedRedirectUrl,
          app: appName,
        },
      });
    }
  }, [redirect_url, appName]);

  const federatedSignIn = (provider) => {
    initiateAmplifyAuth();
    auth
      .federatedSignIn(provider)
      .then(() => {
        setTimeout(() => setShowSSOLogin(false), 1000);
        setShowSSOLogin(false);
        analytics.logEvent("auth", "federated_sign_in_success");
      })
      .catch((err) => {
        setShowSSOLogin(false);
        analytics.logException(`federated_sign_in_failed: ${err.message}`);
        checkAuthErrorAndDoOperation(err, setAlertProps, null, {
          redirectUrl: redirectUrl.current,
        });
      });
  };

  const ssoSignIn = (provider) => {
    setShowSSOLogin(true);
    setTimeout(() => federatedSignIn(provider), 2000); // to show logging you in screen
  };

  const getOrgInfo = (apiEndPoint, subDomain) => {
    setIsLoading(true);
    fetch(`${apiEndPoint}?sub_domain=${subDomain}`)
      .then((res) => res.json())
      .then((config) => {
        if (typeof config === "object") {
          setOrgConfig(config);
          if (config?.sso_provider && !stopAutoLogin) {
            ssoSignIn(config.sso_provider);
          } else {
            setIsLoading(false);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
        analytics.logException(
          `org_info_api_call_failed: ${err.message}`,
          email
        );
      });
  };

  useEffect(() => {
    if (redirectUrl.current && getSignedInWith() !== "NONE")
      window.location.href = redirectUrl.current;
  }, [redirect_url]);

  useEffect(() => {
    try {
      if (!redirectUrl.current) return;
      if (authRedirectUrls.includes(router.route)) return;
      const url = new URL(redirectUrl.current);
      const { pathname } = url;
      const urlOrgSubDomain = pathname.split("/")[1];
      if (!orgConfigApiEndpoint) {
        return;
      }
      if (urlOrgSubDomain) {
        analytics.setCustomDimension("org_sub_domain", urlOrgSubDomain);
        getOrgInfo(orgConfigApiEndpoint, urlOrgSubDomain);
      }
    } catch (err) {}
  }, [redirect_url]);

  App.getInitialProps = async () => ({});

  const size = useWindowSize();
  if (size.width && size.width !== undefined) setScaleFactor(size.width);

  const validateSignInRequest = async () => {
    let idToken = null;
    let signInRequestEmail = null;
    try {
      const credentials = await getCredentials();
      idToken = credentials.idToken;
      const { email: _1, preferred_username: _2 } =
        jwtUtils.getDecodedToken(idToken);
      signInRequestEmail = _1 || _2;
    } catch (err) {
      //
    }
    if (!idToken) {
      analytics.logEvent(
        "auth",
        "authorization token is empty while validating request"
      );
      analytics.logException(
        "authorization token is empty",
        signInRequestEmail
      );
      setAlertProps({
        type: "error",
        text: "Unable to set required cookies.",
        showAlert: true,
      });
      return {
        success: false,
      };
    }
    /* Get instance url */
    let urlOrgSubDomain = null;
    let defaultSelectedOrgSubdomain = null;
    try {
      const url = new URL(redirectUrl.current);
      const { pathname } = url;
      urlOrgSubDomain = pathname.split("/")[1];
      analytics.setCustomDimension("org_sub_domain", urlOrgSubDomain);
    } catch (err) {
      urlOrgSubDomain = null;
    }
    /* Get user info */
    const userInfoData = await fetch(userInfoApiEndpoint, {
      headers: {
        Authorization: idToken,
      },
    })
      .then((res) => res.json())
      .then(async (res) => {
        if (res?.code === 800 || JSON.stringify(res) === "{}") {
          analytics.logException(
            "user_info_api_call_access_denied",
            signInRequestEmail
          );
          setAlertProps({
            type: "error_non_suspendable",
            text: `Sorry, you are not invited. Please ask your team to invite or contact us at ${PeopleCuesSupportEmail}.`,
            showAlert: true,
          });
          setIsLoading(false);
          return null;
        }
        try {
          await analytics.setUserId(res.user_info.user_reference_id);
        } catch (e) {
          analytics.logException(
            'analytics.setUserId failed, "user_reference_id" not found'
          );
        }
        return res;
      })
      .catch((err) => {
        analytics.logEvent("auth", `user_info_api_call_failed: ${err.message}`);
        analytics.logException(
          `user_info_api_call_failed: ${err.message}`,
          signInRequestEmail
        );
        setAlertProps({
          type: "error",
          text: err.message,
          showAlert: true,
        });
        return null;
      });
    if (!userInfoData || !userInfoData.orgs) return { success: false };
    if (userInfoData.orgs.length === 1)
      defaultSelectedOrgSubdomain = userInfoData.orgs[0].sub_domain;
    if (urlOrgSubDomain && !hasInstanceAccess(userInfoData, urlOrgSubDomain)) {
      analytics.logEvent("auth", "access requested for another instance");
      const updatedRedirectUrl = new URL(redirectUrl.current).origin;
      return {
        success: true,
        updatedRedirectUrl: defaultSelectedOrgSubdomain
          ? `${updatedRedirectUrl}/${defaultSelectedOrgSubdomain}/home`
          : updatedRedirectUrl,
      };
    }
    if (
      (urlOrgSubDomain || defaultSelectedOrgSubdomain) &&
      isSignedInWithCognito()
    ) {
      if (
        isCognitoEnabled(
          userInfoData,
          urlOrgSubDomain || defaultSelectedOrgSubdomain
        )
      )
        return { success: true };
      else {
        analytics.logEvent(
          "auth",
          "cognito sign in request for a cognito disabled org"
        );
        setAlertProps({
          type: "error",
          text: `Email login is blocked by ${
            getOrgDetailsFromSubDomain(
              userInfoData,
              urlOrgSubDomain || defaultSelectedOrgSubdomain
            ).name
          }. Kindly use Google sign-in.`,
          showAlert: true,
        });
        if (userInfoData?.orgs.length === 1) setOrgConfig(userInfoData.orgs[0]);
        return { success: false };
      }
    }
    analytics.logEvent("auth", `success, mode: ${getSignedInWith()}`);
    return { success: true, updatedRedirectUrl: redirectUrl.current };
  };

  const authEventListener = ({ payload }) => {
    const { event, data, message } = payload;
    let emailCopy = null;
    const signInInput = document.querySelector("[placeholder='Email id']");
    if (!emailRef.current && signInInput && signInInput.value)
      emailRef.current = signInInput.value;
    if (emailRef.current) {
      emailCopy = emailRef.current;
      ReactGA.set({ dimension1: emailCopy });
    }
    Sentry.withScope(async (scope) => {
      scope.setTags({
        email: emailRef.current,
        error_code: data?.code,
        error_message: data?.message || message,
      });
      switch (event) {
        case "signedIn": {
          const idToken = (
            await auth.fetchAuthSession()
          ).tokens?.idToken.toString();
          if (idToken) {
            analytics.logEvent("auth", "user_signed_in");
            setCredentials.cognito(idToken);
            const { success, updatedRedirectUrl } =
              await validateSignInRequest(emailCopy);
            if (success) {
              analytics.logEvent("auth", "sign_in_success");
              window.location.href = updatedRedirectUrl || redirectUrl.current;
            } else {
              initiateAmplifyAuth();
              await auth.signOut().catch(() => null);
              analytics.logException("sign_in_failure");
              clearCredentials.all();
              router.replace({
                pathname: "/",
                query: {
                  redirect_url: redirectUrl.current,
                  app: appName,
                },
              });
            }
          }
          break;
        }
        case "signedOut":
          analytics.logEvent("auth", "user_signed_out");
          break;
        // signUp, signIn_failure,forgotPassword_failure  event no longer supported by Hub
        case "signInWithRedirect":
          analytics.logEvent("auth", "Sign in with redirect initiated");
          break;
        case "signInWithRedirect_failure":
          analytics.logException(
            `Sign in with redirect failure ${data?.error}`,
            null,
            true,
            {
              data: JSON.stringify(data),
              message,
              event,
            }
          );
          break;
        case "tokenRefresh_failure":
          analytics.logException(
            `auth token could not be refreshed, ${data?.error || message}`,
            null,
            true,
            {
              data: JSON.stringify(data),
              message,
              event,
            }
          );
          break;
        default:
          break;
      }
    });
  };

  useEffect(() => {
    if (!redirectUrl.current || !appName) return;
    const listener = Hub.listen("auth", authEventListener);
    return () => listener();
  }, [redirect_url, appName]);

  useEffect(() => {
    emailRef.current = email;
  }, [email]);

  if (showSSOLogin)
    return (
      <ThemeProvider theme={theme}>
        <GlobalStyles size={size} />
        <div className="loaderContainer">
          <div className="ssoLogin"> Logging you in...</div>
        </div>
      </ThemeProvider>
    );

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles size={size} />
      <Alert alert={alertProps} />
      <Component
        {...pageProps}
        analytics={analytics}
        redirect_url={redirectUrl.current}
        app={appName}
        isEmailVerificationRequired={isEmailVerificationRequired}
        microsoftAuthEnabled={microsoftAuthEnabled}
        email={email}
        setEmail={setEmail}
        setAlertProps={setAlertProps}
        setIsLoading={setIsLoading}
        orgConfig={orgConfig}
        isInitialized={isInitialized}
        isSignInProgress={isSignInProgress}
        setIsSignInProgress={setIsSignInProgress}
        validateSignInRequest={validateSignInRequest}
        ssoSignIn={ssoSignIn}
        setIsEmailVerificationRequired={setIsEmailVerificationRequired}
      />
      {isLoading && (
        <div className="loaderContainer">
          <div className="loaderWrapper">
            <div className="loader">Loading...</div>
          </div>
        </div>
      )}
    </ThemeProvider>
  );
};

export default App;
