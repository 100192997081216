import React from 'react';

const Image = React.memo(function Image(props) {
  return (
    <img
      hidden={props.hidden}
      id={props.id}
      key={props.id}
      className={`${props.className} icon`}
      src={props.src}
      alt={props.alt}
      onClick={props.onClick}
      ref={props.ref}
    />
  );
});

export default Image;
