import ReactGA from "react-ga4";
import * as Sentry from "@sentry/nextjs";
import mixpanel from "mixpanel-browser";

// Constants
const EventTypes = {
  PAGE_VIEW: "Page View",
  EXCEPTION: "Exception",
};

// Initialize Google Analytics
const initGA = () => {
  ReactGA.initialize(process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID);
};

// Initialize Mixpanel
const initMixpanel = () => {
  mixpanel.init(process.env.NEXT_PUBLIC_MIXPANEL_TOKEN, {
    track_pageview: "full-url",
  });
};

const setCustomDimension = (dimension, value) => {
  try {
    ReactGA.gtag("set", "user_properties", {
      [dimension]: value,
    });
    mixpanel.people.set({ [dimension]: value });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(
      "****analytic_error****",
      "method:setCustomDimension",
      `dimension:${dimension}`,
      `value:${value}`,
      e
    );
  }
};

// Initialize all analytics
const initAnalytics = () => {
  initGA();
  initMixpanel();
};

// Log page views
const logPageView = (page) => {
  ReactGA.set({ page });
  try {
    mixpanel.track(EventTypes.PAGE_VIEW, { page });
  } catch (e) {
    console.error("Error logging page view to analytics", e);
  }
};

// Log events
const logEvent = (category = "", action = "") => {
  if (category && action) {
    try {
      ReactGA.event({ category, action });
      mixpanel.track(action, { category });
    } catch (e) {
      console.error("Error logging event to analytics", e);
    }
  }
};

// Set user ID
const setUserId = async (userId) => {
  try {
    userId = userId ? userId.toString().toLowerCase() : userId;
    await mixpanel.identify(userId);
  } catch (e) {
    console.error("Error setting user ID in analytics", e);
  }
};

// Get Mixpanel distinct ID
const getAnalyticsDistinctId = () => {
  try {
    return mixpanel.get_distinct_id();
  } catch (e) {
    console.error("Error getting Mixpanel distinct ID", e);
    return null;
  }
};

// Log exceptions
const logException = (
  description = "",
  userIdentifier = null,
  fatal = false,
  otherAttributes = {}
) => {
  if (description) {
    ReactGA.event({ category: "error", action: description });
    try {
      mixpanel.track(`${EventTypes.EXCEPTION}: ${description}`, {
        description,
        fatal,
        userIdentifier,
        ...otherAttributes,
      });
    } catch (e) {
      console.error("Error logging exception to Mixpanel", e);
    }
    Sentry.withScope((scope) => {
      scope.setTag("user_identifier", userIdentifier);
      Sentry.captureException(new Error(`Common auth: ${description}`));
    });
  }
};

// Log exceptions by error object
const logExceptionByError = (error) => {
  if (error.message) {
    ReactGA.event({ category: "error", action: error.message });
    try {
      mixpanel.track(EventTypes.EXCEPTION, {
        description: error.message,
        fatal: true,
      });
    } catch (e) {
      console.error("Error logging exception to Mixpanel", e);
    }
    Sentry.captureException(error);
  }
};

export default {
  initGA,
  initMixpanel,
  initAnalytics,
  logPageView,
  logEvent,
  logException,
  setUserId,
  logExceptionByError,
  getAnalyticsDistinctId,
  setCustomDimension,
};
