let scaleFactor = 1;
export const minSupportedWidth = 1024;
export const maxSupportedWidth = 1366;
export const minSupportedWidthMobile = 320;
export const maxSupportedWidthMobile = 360;
export const maxSupportedWidthTablet = 768;
export const setScaleFactor = innerWidth => {
  if (innerWidth <= maxSupportedWidthTablet) {
    innerWidth = maxSupportedWidthMobile;
  } else if (innerWidth < minSupportedWidth) {
    innerWidth = minSupportedWidth;
  } else if (innerWidth > maxSupportedWidth) {
    innerWidth = maxSupportedWidth;
  }
  scaleFactor =
    innerWidth <= maxSupportedWidthTablet
      ? innerWidth / 360
      : innerWidth / 1366;
};

export const getScaleFactor = () => scaleFactor;
