import isEmpty from "validator/lib/isEmpty";
import { AuthError } from "aws-amplify/auth";
import Router from "next/router";
import analytics from "./analytics";
import { ErrorMessageMap } from "./constants";
import { PeopleCuesSupportEmail } from "../constants";

export const getNameFromEmail = (str) => {
  if (str == null) return str;
  return capitalizeString(
    str.toString().trim().split("@")[0].split(".")[0].split("_")[0]
  );
};

export const isEmptyString = (str) =>
  str == null || isEmpty(str.toString().trim());

export const capitalizeString = (str) => {
  if (str == null || str.trim().length === 0) return str;
  return str[0].toUpperCase() + str.slice(1);
};

export const getOrgDetailsFromSubDomain = (userInfoData, domain) =>
  userInfoData?.orgs?.find((d) => d.sub_domain === domain);

export const isCognitoEnabled = (userInfoData, domain) =>
  !!(
    getOrgDetailsFromSubDomain(userInfoData, domain) &&
    !getOrgDetailsFromSubDomain(userInfoData, domain).cognito_auth_disabled
  );

export const hasInstanceAccess = (userInfoData, domain) =>
  !!getOrgDetailsFromSubDomain(userInfoData, domain);

export const isNotEmptyString = (str) => !isEmptyString(str);

export const getEncodedEmail = (email) => {
  try {
    return Buffer.from(email, "ascii").toString("base64");
  } catch (_) {
    return null;
  }
};

export const checkAuthErrorAndDoOperation = (
  err,
  setAlertProps,
  fallbackFn,
  props
) => {
  const redirectUrl = props.redirectUrl;
  if (err instanceof AuthError) {
    switch (err.name) {
      case "UserNotConfirmedException":
        analytics.logEvent(
          "auth",
          "unconfirmed_user_auto_trigger_confirmation_flow"
        );

        Router.push({
          pathname: `/sign-up-invite/${getEncodedEmail(props.email)}`,
          query: {
            redirect_url: redirectUrl,
            app: "PeopleCues",
            flow: "confirmation",
          },
        });
        break;
      case "UserNotFoundException":
        setAlertProps({
          type: "error",
          text: "Couldn't find your account. Please try signing up.",
          showAlert: true,
        });
        analytics.logEvent(
          "auth",
          "no_cognito_account_auto_trigger_signup_flow"
        );
        Router.push({
          pathname: `/sign-up-invite/${getEncodedEmail(props.email)}`,
          query: {
            redirect_url: redirectUrl,
            app: "PeopleCues",
          },
        });
        break;
      case "UserAlreadyAuthenticatedException":
        setAlertProps({
          type: "error",
          text: "User is already authenticated, redirecting to PeopleCues...",
          showAlert: true,
        });
        window.location.reload();
        break;
      case "UserLambdaValidationException":
        if (
          err.message ===
          "PreAuthentication failed with error Invalid request for cognito pre auth.."
        ) {
          analytics.logEvent("auth", "uninvited_user_sign_in_request");
          setAlertProps({
            type: "error",
            text: `Sorry, you are not invited. Please ask your team to invite or contact us at ${PeopleCuesSupportEmail}.`,
            showAlert: true,
          });
          break;
        }
        analytics.logEvent(
          "auth",
          `UserLambdaValidationException: ${err?.message || message}`
        );
        break;

      case "InvalidParameterException":
        if (
          err.message ===
          "Cannot reset password for the user as there is no registered/verified email or phone_number"
        ) {
          analytics.logException(
            `forgot_password_failure: ${err?.message || message}`
          );
          Router.push({
            pathname: `/sign-up-invite/${getEncodedEmail(props.email)}`,
            query: {
              redirect_url: redirectUrl,
              app: "PeopleCues",
              flow: "confirmation",
            },
          });
        }
        break;

      default:
        setAlertProps({
          type: "error",
          text: (err?.code && ErrorMessageMap[err.code]) || err.message,
          showAlert: true,
        });
        if (
          err &&
          ["NetworkError", "NotAuthorizedException"].indexOf(err.code) === -1
        )
          analytics.logException(`sign_in_failure: ${err?.message}`);
        if (fallbackFn) fallbackFn();
        break;
    }
    return;
  }
};
