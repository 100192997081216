export const isStageEnvironment =
  process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'staging';

export const isProdEnvironment =
  process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'production';

export const appRedirectUrl = process.env.NEXT_PUBLIC_APP_REDIRECT_URL;

export const orgConfigApiEndpoint =
  process.env.NEXT_PUBLIC_APP_ORG_CONFIG_API_ENDPOINT;

export const userInfoApiEndpoint =
  process.env.NEXT_PUBLIC_APP_USER_INFO_API_ENDPOINT;

export const appName = 'PeopleCues';

export const ErrorMessageMap = {
  NetworkError: 'Check your internet connection and try again.',
  NotAuthorizedException:
    'Sorry, your username or password is incorrect. Please try again.'
};


export const cognitoConstants = {
  CONFIRM_SIGN_UP: 'CONFIRM_SIGN_UP',
  COMPLETE_AUTO_SIGN_IN: 'COMPLETE_AUTO_SIGN_IN',
  UserNotConfirmedException: 'UserNotConfirmedException'
}